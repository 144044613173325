const moment = require('moment-timezone');
const axios = require('axios');

async function tableSummary(startDate, endDate) {
    const token = `${localStorage.getItem('token')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const startAt = moment(String(startDate)).format('YYYY-MM-DD')
    const endAt = moment(String(endDate)).format('YYYY-MM-DD')
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    }
    const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
    }
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/table/statistics/dashboard',
            headers: headers,
            params: params,
            method: 'GET',
        })

        return res.data
    } catch (error) {
        // Handle errors
        console.log(error)
        return null
    }
}

async function tableData(startDate, endDate) {
    const token = `${localStorage.getItem('token')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const startAt = moment(String(startDate)).format('YYYY-MM-DD')
    const endAt = moment(String(endDate)).format('YYYY-MM-DD')
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    }
    const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
    }
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/table/statistics/data',
            params: params,
            headers: headers,
            method: 'GET',
        })
        const responseData = {
            'data': res.data.data,
        }
        return responseData
    } catch (error) {
        // Handle errors
        console.log(error)
        return null
    }
}

export { tableData, tableSummary, }