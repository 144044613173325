<template>
  <CModal :show.sync="isVisible" color="danger" :title="$t('tokenExpire')" centered>
    <div>
      <br />
      <h3 class="text-center" v-html="$t('plsLoginAgain')"></h3>
      <br />
    </div>

    <template #footer>
      <CRow class="w-100">
        <CCol col="6">
          <CButton color="danger" block @click="handleLogout">
            {{ $t("submit") }}
          </CButton>
        </CCol>
        <CCol col="6">
          <CButton color="light" block @click="closeModal">
            {{ $t("cancel") }}
          </CButton>
        </CCol>
      </CRow>
    </template>
  </CModal>
</template>

<script>
import firebase from "firebase"

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:isVisible", false)
    },
    handleLogout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.clear();
          window.location.href = "/login"
        })
        .catch((error) => {
          console.error("Error logging out:", error);
        });
    },
  },
};
</script>
