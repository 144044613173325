// controllers/TableController.js
const tableModel = require("../../model/report/table")

exports.fetchTableData = async (startDate, endDate, page) => {
  try {
    let tableData = await tableModel.tableData(startDate, endDate, page);
    return tableData.data
  } catch (error) {
    console.error('Error fetching table data:', error);
    return null
  }
}

exports.fetchTableSummary = async (startDate, endDate) => {
  try {
    let tableSummary = await tableModel.tableSummary(startDate, endDate);
    let tableSummaryData = tableSummary.data;

    const filteredData = tableSummaryData.filter(item => item.bill !== 0);

    const avgData = filteredData.reduce((acc, item) => {
      acc.totalNumCust += item.numCust
      acc.totalBill += item.bill
      acc.totalDuration += item.duration
      acc.totalGrandTotal += item.grandTotal
      acc.totalItems += item.items
      acc.totalTables++;

      acc.totalAvgNumCust += item.numCust / item.bill
      acc.totalAvgDuration += item.duration / item.bill
      acc.totalAvgGrandTotal += item.grandTotal / item.bill
      acc.totalAvgItems += item.items / item.bill

      return acc;
    }, {
      totalNumCust: 0,
      totalBill: 0,
      totalDuration: 0,
      totalGrandTotal: 0,
      totalItems: 0,
      totalTables: 0,
      totalAvgNumCust: 0,
      totalAvgDuration: 0,
      totalAvgGrandTotal: 0,
      totalAvgItems: 0
    });

    let totalAvgItemsPerTable, totalAvgNumCustPerTable, totalAvgDurationPerTable, totalAvgGrandTotalPerTable, avgTotalBill, totalAvgHoursDurationPerTable,totalAvgMinsDurationPerTable;

    if (avgData.totalTables === 0) {
      totalAvgItemsPerTable = 0
      totalAvgNumCustPerTable = 0
      totalAvgDurationPerTable = 0
      totalAvgGrandTotalPerTable = 0
      avgTotalBill = 0
      totalAvgHoursDurationPerTable = 0
      totalAvgMinsDurationPerTable = 0
    } else {
      totalAvgItemsPerTable = Math.round(avgData.totalAvgItems / avgData.totalTables * 100) / 100
      totalAvgNumCustPerTable = Math.round(avgData.totalAvgNumCust / avgData.totalTables)
      totalAvgDurationPerTable = ((avgData.totalAvgDuration / avgData.totalTables )/ 60 * 100) / 100
      totalAvgGrandTotalPerTable = Math.round(avgData.totalAvgGrandTotal / avgData.totalTables * 100) / 100
      avgTotalBill = Math.round((avgData.totalBill / avgData.totalTables * 100) /100)
      totalAvgHoursDurationPerTable = Math.floor(totalAvgDurationPerTable)
      totalAvgMinsDurationPerTable = Math.floor((totalAvgDurationPerTable % 1) * 60)
    }

    return {
      totalAvgNumCustPerTable,
      totalAvgDurationPerTable,
      totalAvgGrandTotalPerTable,
      totalAvgHoursDurationPerTable,
      totalAvgMinsDurationPerTable,
      totalAvgItemsPerTable,
      avgTotalBill,
      totalNumCust: avgData.totalNumCust,
      totalBill: avgData.totalBill,
      totalDuration: avgData.totalDuration,
      totalGrandTotal: avgData.totalGrandTotal,
      totalItems: avgData.totalItems
    };
  } catch (error) {
    console.error('Error fetching table data:', error);
    return null;
  }
};





exports.tableReport = async (startDate, endDate,) => {
  try {
    let tableReport = await tableModel.exportTableReport(startDate, endDate,);
    return tableReport
  } catch (error) {
    console.error('Error fetching table data:', error);
    return null
  }
}

