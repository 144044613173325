<template>
    <div>
        <div class="text-center" style="width: 100%;" id="sticker-container">
            <div class="static-sticker-template" v-for="(table, index) in tables" :key="'sticker' + index">
                <div>
                    <div name="silom-logo">
                        <h2>สแกนสั่งอาหาร</h2>
                        <h4>Scan to Order</h4>
                    </div>
                    <div class="mb-3" name="qr">
                        <img class="static-qr-img" :src="generateQR(table.objectId)">
                    </div>
                    <div name="shop-name">
                        <h2> <img src="/img/static-icon2.webp" width="8%"> {{ table.name }}</h2>
                        <h5>{{ shop.shopName }}</h5>
                        <small><img src="/SilomPOS_logo.png" width="15%"></small>
                    </div>
                </div>
            </div>
        </div>
        <button @click="exportToPDF">Export as PDF</button>
    </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { mapGetters } from 'vuex'
import table from '../../controller/report/tableController'
import pos from '@/services/local';

export default {
    data() {
        return {
            tables: []
        };
    },
    computed: {
        ...mapGetters(['shops', 'users', 'shopsetting']),

        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
        },
        shop() {
            return this.shops.find((i) => i.objectId === this.shopObjectId)
        },
        datashop: {
            get() {
                return this.$store.getters.shopsetting
            },
            set(newValue) {
                return this.$store.dispatch('setShopSetting', newValue)
            },
        },
        shopsUid() {
            return `${localStorage.getItem('shopsUid') || localStorage.getItem('uid') }`
        },
    },
    created() {
        this.getTable()

    },
    methods: {
        generateQR(tableObjectId) {
            var QRCode = require('qrcode')
            let qrString = ''
            let url = `${process.env.VUE_APP_QR_ORDER_WEB_URL}/dine-in/home?uid=${this.shopsUid}&shopObjectId=${this.shopObjectId}&tableObjectId=${tableObjectId}&pax=1`

            QRCode.toDataURL(url, function (err, url) {
                qrString = url
            })

            return qrString
        },
        getTable() {
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v1.0/' + this.uid + '/Shop/gettablefb/data',
                params: params,
            }).then((response) => {
                this.tables = response?.data?.data || []
            })
        },
        async exportToPDF() {
            this.$emit('uploaded', false)
            const pdf = new jsPDF('portrait', 'mm', 'a4');
            const stickerContainer = document.getElementById('sticker-container');
            const stickers = stickerContainer.children;

            // We'll use these variables to position each sticker on the A4 page (2x3 grid)
            const stickersPerPage = 6; // Number of stickers per page (2x3 grid)
            const stickerWidth = 80; // Width of each sticker in mm
            const stickerHeight = 80; // Height of each sticker in mm
            const horizontalSpacing = 20; // Horizontal spacing between stickers in mm
            const verticalSpacing = 10; // Vertical spacing between stickers in mm
            const marginTop = 20; // Top margin in mm
            const marginLeft = 20; // Left margin in mm

            for (let i = 0; i < stickers.length; i++) {
                // If we're at the start of a new page, add a page except for the first one
                if (i > 0 && i % stickersPerPage === 0) {
                    pdf.addPage(); // Add a new page after every 6 stickers
                }

                // Render each sticker as an image using html2canvas
                const canvas = await html2canvas(stickers[i], { backgroundColor: 'white' });

                // Convert the canvas to an image format (PNG) suitable for jsPDF
                const imgData = canvas.toDataURL('image/jpeg', 0.8)

                // Calculate the X and Y position for the sticker in the 2x3 grid
                const x = marginLeft + (i % 2) * (stickerWidth + horizontalSpacing);
                const y = marginTop + Math.floor((i % stickersPerPage) / 2) * (stickerHeight + verticalSpacing);

                // Add the image to the PDF at the calculated position
                pdf.addImage(imgData, 'JPEG', x, y, stickerWidth, stickerHeight);
            }

            // Save the PDF
            pdf.save('stickers.pdf');
            this.$emit('uploaded', true)
        }


    },
};
</script>

<style>
.static-sticker-template {
    width: 100%;
    background-color: #ffffff;
    border: 2px solid #dddddd;
    aspect-ratio: 1/1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(55, 55, 55);
    text-align: center
}

.static-qr-img {
    width: 50%;
    border: 2px solid #dddddd;
    border-radius: 10px;
}
</style>
