<template>
    <div>
        <div v-if="selfOrderEnabled">
            <div id="qrOrderAlert" v-if="alertStatus" @click="() => { alertStatus = false }">
                <CAlert color="success">{{ $t('saveSuccess') }}</CAlert>
            </div>
            <CRow class="text-dark">
                <CCol sm="12" md="6">
                    <div id="qrOrderHelpButton" class="row mb-3 mt-2 pl-2">
                        <h3 class="text-left text-dark d-flex">{{ $t('qrMobileConfig') }} (QR
                            Order)
                        </h3>
                        <HelpButton :isHeader="false" class="ml-2"></HelpButton>
                    </div>
                    <form>
                        <div class="form-group">
                            <label class="h5 mt-2" for="greeting1">{{ $t('shopGreetingTitle') }}</label>
                            <input type="text" v-model="greetingMessage1" class="form-control" id="greeting1">
                            <small id="emailHelp" class="form-text text-muted">{{ $t('shopGreetingDialog') }}</small>
                        </div>
                        <div class="form-group">
                            <label class="h5 mt-2" for="greeting2">{{ $t('shopGreetingDescription') }}</label>
                            <input v-model="greetingMessage2" type="text" class="form-control" id="greeting2">
                            <small id="greeting2" class="form-text text-muted">{{ $t('shopGreetingDialog') }}</small>
                        </div>
                        <div class="form-group">
                            <label class="h5 mt-2" for="greeting2">{{ $t('termOfService') }}
                                <i :key="'infoTerm' + componentIndex" v-c-tooltip="getToolTips('showOnFirstLogin')"
                                    class="fi fi-rr-info p-0"></i></label>
                            <textarea maxlength="200" @blur="showPolicyBox = false" @focus="showPolicyBox = true"
                                ref="policy_input" v-model="policy" type="text" class="form-control"
                                id="policy_input"></textarea>
                            <small id="emailHelp" class="form-text text-muted">{{ $t('exTermOfService')
                                }}</small>
                        </div>
                        <div class="form-group">
                            <label class="h5 mt-2" for="greeting2">{{ $t('qrType') }}
                            </label>
                            <div class="d-flex mb-1">
                                <CButton id="qrOrderDynamicType" :key="'qrDynamicType' + componentIndex"
                                    @click="setQRType('DYNAMIC')" v-c-tooltip="getToolTips('dynamicQRDespt')"
                                    :class="{ 'display-option-selected': qrType == 'DYNAMIC', 'display-option': qrType !== 'DYNAMIC' }">
                                    <div class="mt-2">
                                        <i class=" fi fi-rr-file h5"></i>
                                        <h5 class="mt-1">{{ $t('dynamicQR') }}</h5>
                                    </div>
                                </CButton>
                                <CButton id="qrOrderStaticType" :key="'qrStaticType' + componentIndex"
                                    @click="qrTypeModal = true" v-c-tooltip="getToolTips('staticQRDespt')"
                                    :class="{ 'display-option-selected': qrType == 'STATIC', 'display-option': qrType !== 'STATIC' }"
                                    style="position: relative;">
                                    <span class="badge-new ">new</span>
                                    <div class="mt-2">
                                        <i class="fi fi-rr-sticker h5"></i>
                                        <h5 class="mt-1">{{ $t('staticQR') }}</h5>
                                    </div>
                                </CButton>
                                <CButton id="qrOrderStaticStickerButton" v-if="qrType == 'STATIC'"
                                    @click="staticStackerModal = true" class="static-sticker-btn">
                                    <div class="p-2">
                                        <span>{{ $t('printFoodOrderStickers') }}<br> (Static QR)</span>
                                    </div>
                                </CButton>
                            </div>
                            <small id="qrOrderTypeSuggest" v-html="$t('qrTypeSuggest')"
                                class="text-description text-muted">
                            </small>
                        </div>
                        <div class="form-group">
                            <label class="h5 mt-2" for="greeting2">{{ $t('menuLayout') }}
                            </label>
                            <div class="d-flex">
                                <CButton id="qrOrderGridLayout" :key="'infoGrid' + componentIndex"
                                    v-c-tooltip="getToolTips('gridSuggest')" @click="setDisplay('grid')"
                                    :class="{ 'display-option-selected': display === 'grid', 'display-option': display !== 'grid' }">
                                    <div class="mt-2">
                                        <i class="fi fi-rr-apps h5"></i>
                                        <h5 class="mt-1">{{ $t('gridLayout') }}</h5>
                                    </div>
                                </CButton>
                                <CButton id="qrOrderListLayout" :key="'infoList' + componentIndex"
                                    v-c-tooltip="getToolTips('listSuggest')" @click="setDisplay('list')"
                                    :class="{ 'display-option-selected': display === 'list', 'display-option': display !== 'list' }">
                                    <div class="mt-2">
                                        <i class="fi fi-rr-list h5"></i>
                                        <h5 class="mt-1">{{ $t('listLayout') }}</h5>
                                    </div>
                                </CButton>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="h5 mt-2" for="greeting2">{{ $t('shopThemeColor') }}</label>
                            <div class="">
                                <CButton id="qrOrderThemeColorPicker"
                                    v-c-tooltip="{ content: getColorName(i), placement: 'top' }"
                                    @click="setThemeColor(i)" v-for="i in themeColor" :key="'color' + i"
                                    :style="getThemeColor(i)" class="theme-color">
                                </CButton>
                                <br>
                                <span class="pl-1">{{ $t('selfCustom') }} </span>
                                <input @change="updateTheme" type="color" class="theme-color" id="exampleColorInput"
                                    :value="selectedTheme" title="Choose your color">
                            </div>
                            <small id="qrOrderThemeSuggest" class="text-description text-muted">*{{
                                $t('themeSuggest')
                                }}</small>
                        </div>
                    </form>

                    <div class="mb-3">
                        <label class="mt-3 h5">{{ $t('addCoverImage') }} <i @click="coverExampleModal = true"
                                class="fi fi-rr-info p-0 h5"></i></label>
                        <br>
                        <small class="text-description text-muted">*{{ $t('addBannerSuggestion') }}</small>
                        <CRow id="qrOrderCoverImageRow" class="mt-2 ml-0">
                            <CButton id="qrOrderToggleCoverModal" @click="toggleCoverModal()"
                                style="position: relative; width: 180px; height: 90px; background-color: #d8dbe0; color: #878787; font-size: medium; overflow: hidden;"
                                class="img-fluid">
                                <!-- Image -->
                                <img id="qrOrderCoverImage"
                                    v-if="localCoverImage !== undefined && localCoverImage !== ''"
                                    :src="localCoverImage"
                                    style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;">
                                <!-- '+' placeholder -->
                                <div v-else style="display: flex; align-items: center; justify-content: center;">+
                                </div>
                            </CButton>
                        </CRow>
                    </div>
                    <CModal id="qrOrderExampleCoverModal" :title="$t('exampleCover')" color="success"
                        :show.sync="coverExampleModal" centered>
                        <div class="text-center pl-5 pr-5 pt-1 pb-2">
                            <img id="qrOrderExampleCoverImage" class="mb-3" width="50%" src="/img/CoverQR.webp"><br>
                            <span id="qrOrderExampleCoverDescription">{{ $t('coverDespt') }}</span>
                        </div>
                        <template #footer-wrapper>
                            <div></div>
                        </template>
                    </CModal>
                    <CModal id="qrOrderStaticStickerModal" :closeOnBackdrop="false"
                        :title="$t('printFoodOrderStickers')" color="success" :show.sync="staticStackerModal" centered>

                        <div id="qrOrderStaticStickerContent" class="text-center pt-2 ">
                            <h5>{{ $t('sampleSticker') }}</h5>
                            <img id="qrOrderStaticStickerImage" src="/img/QRStatic.webp" width="70%"
                                style="border-radius: 10px;border: 2px solid #dddddd">
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-6 col-sm-12 text-center">
                                <img src="/img/QRStatic.webp" width="100%"
                                    style="border-radius: 10px;border: 2px solid #dddddd">
                            </div>
                            <div class="col-md-6 col-sm-12 d-flex align-items-center p-2">
                                <div class="text-left">
                                    <h5>{{ $t('registerToReceiveFoodOrderStickers') }}</h5>
                                    <ul style="list-style: none; padding-left: 0;">
                                        <li>✅ {{ $t('stickerSize') }}</li>
                                        <li>✅ {{ $t('preciseEdgeCutting') }}</li>
                                        <li>✅ {{ $t('waterproofPVCMaterial') }}</li>
                                        <li>✅ {{ $t('deliveryWithin14Days') }}</li>
                                        <li>✅ {{ $t('firstTimeRegistrationFree') }}</li>
                                    </ul>
                                    <CButton class="static-qr-contact-line"
                                        ref="https://liff.line.me/1645278921-kWRPP32q/?accountId=silompos">
                                        <img src="/img/line-icon.png" width="25px" class="mr-1"> {{ $t('contactus') }}
                                    </CButton>
                                </div>
                            </div>
                        </div> -->
                        <template #footer-wrapper>
                            <div id="qrOrderStaticStickerFooter" class="text-center">
                                <!-- {{ $t('or') }} -->
                                <div class="p-3">
                                    <CAlert id="qrOrderGeneratingStickerAlert" v-if="!loading" color="info">
                                        {{ $t('generatingStickerQR') }}
                                    </CAlert>
                                    <CButton id="qrOrderExportStickerButton" :disabled="!loading" block
                                        @click="exportSticker()" color="success">
                                        <template v-if="loading">{{ $t('exportToPdf') }}</template>
                                        <template v-else>
                                            <CSpinner id="qrOrderExportingSpinner" class="mr-1" color="white"
                                                size="sm" />{{ $t('exportingDespt') }}
                                        </template>
                                    </CButton>
                                </div>
                            </div>
                        </template>
                    </CModal>
                    <div style="position: fixed; z-index: -1">
                        <StaticQRSticker @uploaded="handleSticker" ref="staticSticker"></StaticQRSticker>
                    </div>
                    <div>
                        <label class="mt-3 h5">{{ $t('addBanner') }}</label><br>
                        <small class="text-description text-muted">*{{ $t('addBannerSuggestion') }}</small>
                        <CRow class="mt-2 mb-5">
                            <div style="width: 40%; aspect-ratio: 2/1; " class="m-2"
                                v-for="index in additionImageAmount" :key="'addImage' + index">
                                <CButton @click="selectBannerImage(index - 1)"
                                    style="position: relative;width: 100%;aspect-ratio: 2/1; background-color: #d8dbe0; color: #878787; font-size: medium; overflow: hidden;"
                                    class="img-fluid">
                                    <CBadge v-if="items.length >= index && items[index - 1] !== null" color="success"
                                        style="z-index: 1000;position: absolute;top: 0px;left: 0px;">
                                        <i class="fi fi-rr-link"></i> {{ items[index - 1].action || '' }}
                                    </CBadge>
                                    <!-- Image -->
                                    <img v-if="localBannerImage[index - 1] !== undefined && localBannerImage[index - 1] !== null"
                                        :src="localBannerImage[index - 1]"
                                        style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;">
                                    <!-- '+' placeholder -->
                                    <div v-else style="display: flex; align-items: center; justify-content: center;">+
                                    </div>
                                </CButton>
                            </div>
                        </CRow>
                    </div>
                </CCol>
                <CCol class="d-flex justify-content-center mt-5" sm="6" md="6">
                    <div>
                        <div class="container-phone">
                            <div class="phone-mocking text-dark">

                                <div class="d-flex justify-content-between">
                                    <span id="qrOrderSidebarToggle" :style="currentTheme" class="toggles"
                                        v-c-emit-root-event:toggle-sidebar-mobile>
                                        <i class="fi fi-rr-menu-burger"></i>
                                    </span>
                                    <span style="text-decoration: underline;" class="pt-1 t">{{
                                        $t('tableEx') }}</span>
                                    <span :style="currentTheme" class="callStaff ">
                                        <i class="fi fi-rr-cowbell"></i>
                                    </span>
                                </div>
                                <div id="qrOrderGreetingMessage1" class="mt-4">
                                    <h5>{{ greetingMessage1 }}</h5>
                                    <p style="font-size: smaller;" class="text-description">{{ greetingMessage2 }}
                                    </p>
                                </div>
                                <div>
                                    <carousel id="qrOrderCarousel" :perPage="1" :autoplay="true" :loop="true"
                                        :adjustableHeight="true" :paginationColor="selectedTheme"
                                        paginationPosition="bottom-overlay" class="mt-3">

                                        <slide v-for="(image, index) in localBannerImage"
                                            v-if="image !== undefined && image !== null" :key="'image' + index">
                                            <div :id="'qrOrderCarouselSlide' + index" class="banner" :style="{
                                                'background-image': `url('${image}'), url('${noImg}')`,
                                                width: '100%',
                                                'background-size': 'cover',
                                                'background-position': 'center center',
                                            }"></div>
                                        </slide>

                                    </carousel>
                                </div>

                                <div class="mt-3">
                                    <p class="pl-2">{{ $t('recommendMenu') }} </p>
                                    <div class="recommend-container ">
                                        <div v-for="(object, index) in recommendedProducts" :key="index"
                                            :id="'isRecommend' + index" class="col-4 pr-2 pl-2">
                                            <div>
                                                <div class="square-box shadow-menu" :style="{
                                                    'background-image': `url('${object.remoteImagePath || object.ProductSKU.remoteImagePath}'), url('${noImg}')`,
                                                    width: '100%',
                                                    'background-size': 'cover',
                                                    'background-position': 'center center',
                                                }">
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div id="qrOrderRecommendName"
                                                    style="font-size: x-small; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                                    class="mt-2">
                                                    {{ object.name }}
                                                </div>
                                                <span>
                                                    <span hidden>

                                                    </span>
                                                </span>
                                            </div>
                                            <div id="qrOrderRecommendPrice" class="d-flex justify-content-between">
                                                <p style="font-size: xx-small;" class="text-grey pl-1 ">
                                                    ฿{{ object.productSKU.SKUPrice }}
                                                </p>
                                                <small style="font-size: xx-small;">{{ object.unit.name }}/1</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="pl-2 mb-1">{{ $t('foodCategory') }} </p>
                                    <div style="width: 100%;overflow-x: scroll; " class="recommend-container">
                                        <div :style="currentTheme" class="category-btn-active ">
                                            {{ $t('viewAllFood') }}</div>
                                        <div v-for="i in category" :id="'qrOrderCategory' + index"
                                            class="category-btn ">
                                            {{ i.name }}</div>
                                    </div>
                                    <input id="qrOrderSearchByNameInput" class="mt-2 form-control" block
                                        :placeholder="$t('searchByName')"
                                        style="background-color: #eeeeee;font-size:x-small;border: none;"></input>
                                </div>

                                <div class="mb-5 mt-3">
                                    <div v-if="display == 'list'" v-for="(product, index) in products"
                                        :id="'qrOrderListProduct' + index" class="p-1 mt-1 row">
                                        <div class="col-3 p-1">
                                            <div id="qrOrderProductImage"
                                                class="d-flex justify-content-center align-items-center pt-2" :style="{
                                                    backgroundColor: '#dddddd',
                                                    color: '#4B5F71',
                                                    aspectRatio: '1/1',
                                                    borderRadius: '7px',
                                                    fontSize: 'x-large',
                                                    backgroundImage: `url(${product.remoteImagePath || product.ProductSKU.remoteImagePath})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                }">
                                            </div>
                                        </div>
                                        <div id="qrOrderProductName" class="col-9 pt-1">
                                            {{ product.name }}<br>
                                            <small id="qrOrderProductPrice" class="text-description">฿{{
                                                product.productSKU.SKUPrice }}</small>
                                        </div>
                                    </div>
                                    <div v-if="display == 'grid'" class="p-1 mt-1 row">
                                        <div v-for="(product, index) in products" class="col-6 p-1">
                                            <div id="qrOrderGridProductImage"
                                                class="d-flex justify-content-center align-items-center pt-2" :style="{
                                                    backgroundColor: '#dddddd',
                                                    color: '#4B5F71',
                                                    aspectRatio: '1/1',
                                                    borderRadius: '7px',
                                                    fontSize: 'x-large',
                                                    backgroundImage: `url(${product.remoteImagePath || product.ProductSKU.remoteImagePath})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center'
                                                }">
                                            </div>
                                            <div id="qrOrderGridProductDetails"
                                                class="d-flex justify-content-between p-1">
                                                <small id="qrOrderGridProductName"
                                                    style="font-size: x-small; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                                    {{ product.name }}</small>
                                                <small id="qrOrderGridProductPrice">฿{{ product.productSKU.SKUPrice
                                                    }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showPolicyBox" class="policy-box">
                                    <div class="backdrop-pilocy"></div>
                                    <div class="policy-modal p-4">
                                        <h5 style="font-weight: bold;">{{ $t('termOfService') }}</h5>
                                        <div id="qrOrderPolicyContent"
                                            style="color: #768192;height: 100%;overflow: hidden;"
                                            v-html="formattedPolicy">
                                        </div>
                                        <div :style="currentTheme" class="footer-modal">{{ $t('agreed') }}
                                        </div>
                                    </div>
                                </div>

                                <div v-else :style="currentTheme" class="footer-home">
                                    <div class="d-flex justify-content-between pl-2 pr-2">
                                        <span>{{ $t('basket') }} 4 {{ $t('item') }}</span>
                                        <span>666 THB</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
                <CButton id="qrOrderSaveButton" :class="saveButtonClass(isDirty)" @click="confirmModal = true">
                    <i class="fi fi-rr-inbox-out"></i> {{ $t('save') }}
                </CButton>

            </CRow>
            <CModal color="success" :show.sync="additionalModal" :title="$t('selectImg')" centered>
                <div class="row">
                    <label v-if="currentImg == ''" class="col-6 pl-3 text-dark">
                        <input ref="fileInput" type="file" accept="image/*" @change="handleFileChange($event.target)" />
                    </label>
                    <label v-else class="col-6 pl-3 text-dark">
                        {{ $t('linkBannerTo') }} <span class="text-description"> ({{ $t('optionalChoice') }})</span>
                    </label>
                    <label v-if="currentImg !== '' && currentImg !== null" class="col-6 text-right pr-4"
                        style="color: red;" @click="deleteLocalImg()">
                        <i class="fi fi-rr-trash"></i> {{ $t('delete') }}
                    </label>
                    <br>

                    <CCol>
                        <CRow v-if="currentImg !== '' && currentImg !== null" class="mb-4">
                            <CCol md="8" sm="12" class="text-dark">
                                <CRow>

                                    <label id="qrOrderSelectProductOption" class="col-4 col-form-label pl-3">
                                        <input name="options" type="radio" style="accent-color: #29B46B;"
                                            @change="updateAction('Product')" :checked="action == 'Product'" />
                                        &ensp; {{ $t('product') }}
                                    </label>

                                    <label class="col-4 col-form-label" id="qrOrderSelectURLOption">
                                        <input name="options" type="radio" style="accent-color: #29B46B;"
                                            @change="updateAction('URL')" :checked="action == 'URL'" />
                                        &ensp; {{ $t('externalLink') }}
                                    </label>
                                    <label class="col-4 col-form-label" id="qrOrderUnselectOption">
                                        <input name="options" type="radio" style="accent-color: #29B46B;"
                                            @change="updateAction('')" :checked="action == ''" />
                                        &ensp; {{ $t('unSelect') }}
                                    </label>
                                </CRow>
                            </CCol>
                            <CCol md="12" sm="12">
                                <input id="qrOrderProductInput" v-if="action == 'Product'" type="text"
                                    class="form-control " @click="() => { selectItemCard = true }"
                                    v-model="bannerDataInputBox" :placeholder="$t('ParticipatingProducts')" readonly>
                                <input id="qrOrderURLInput" v-else-if="action == 'URL'" type="text" class="form-control"
                                    :placeholder="$t('inputURLPlaceholder')" v-model="bannerDataInputBox">

                                <!--Select Item Card Popup -->
                                <CCard id="qrOrderSelectItemPopup" v-if="selectItemCard == true"
                                    style="z-index: 2000;position: absolute;">
                                    <CCardBody>
                                        <CCardTitle id="qrOrderSelectProductTitle" style="font-size: medium;">{{
                                            $t('selectProductPlease') }}
                                        </CCardTitle>
                                        <CCardText id="qrOrderProductList" class="select-item-card">
                                            <CRow v-for="product in products" :key="product.objectId" class="mt-4"
                                                @click="updateBannerData(product.productSKU)">
                                                <div class="col-2"> <img class="img-fluid" style="border-radius: 5px;"
                                                        :src="product.remoteImagePath"></div>
                                                <div class="text-dark col-10"> {{ product.name }}
                                                </div>
                                            </CRow>
                                        </CCardText>
                                        <CButton id="qrOrderCancelSelection" @click="selectItemCard = false">{{
                                            $t('cancel') }}</CButton>
                                    </CCardBody>
                                </CCard>

                            </CCol>
                        </CRow>

                    </CCol>
                </div>
                <div class="text-center" id="qrOrderImagePreview">
                    <img v-if="currentImg !== ''" style="width:100%;" :src="currentImg" class="image-fluid">
                </div>

                <template #footer-wrapper>
                    <CRow class="p-4">
                        <CCol>
                            <CButton id="qrOrderSaveButton" block color="success"
                                v-if="loadingSaveimg === true && currentImg !== ''" v-on:click="saveBannerLocal">
                                {{ $t('confirm') }}
                            </CButton>
                            <CButton id="qrOrderSaveButtonDisabled" block color="success" v-else disabled>
                                {{ $t('confirm') }}
                            </CButton>
                        </CCol>
                        <CCol>
                            <CButton id="qrOrderCancelButton" block color="light" @click="additionalModal = false">
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>

            <CModal id="qrOrderCoverImageModal" color="success" :show.sync="coverImageModal" :title="$t('selectImg')"
                centered>
                <div class="row">
                    <label v-if="currentImg == ''" class="col-6 pl-3 text-dark">
                        <input id="qrOrderFileInput" ref="fileInput" type="file" accept="image/*"
                            @change="handleFileChange($event.target)" />
                    </label>
                    <label v-else id="qrOrderFileInputEmptyLabel" class="col-6 pl-3">
                    </label>

                    <label id="qrOrderDeleteImage" v-if="currentImg !== '' && currentImg !== null"
                        class="col-6 text-right pr-4" style="color: red;" @click="deleteLocalCoverImg()">
                        <i class="fi fi-rr-trash"></i> {{ $t('deleteImg') }}
                    </label>
                    <br>

                </div>
                <div id="qrOrderImagePreview" class="text-center">
                    <img v-if="currentImg !== ''" style="width:100%;" :src="currentImg" class="image-fluid">
                </div>

                <template #footer-wrapper>
                    <CRow class="p-4">
                        <CCol>
                            <CButton id="qrOrderSaveCoverImage" block color="success"
                                v-if="loadingSaveimg === true && currentImg !== ''" v-on:click="saveCoverImageLocal">
                                {{ $t('confirm') }}
                            </CButton>
                            <CButton id="qrOrderSaveCoverImageDisabled" block color="success" v-else disabled>
                                {{ $t('confirm') }}
                            </CButton>
                        </CCol>
                        <CCol>
                            <CButton id="qrOrderCancelCoverImageModal" block color="light"
                                @click="coverImageModal = false">
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>

            <CModal :closeOnBackdrop="false" color="success" :show.sync="qrTypeModal" :title="$t('setSecurityOfQR')"
                centered>
                <div>
                    <CAlert id="qrOrderQRTypeWarningAlert" v-if="progressColor !== 'danger'" color="warning"> <span
                            v-html="$t('setSecurityOfQRDespt')"></span>
                    </CAlert>
                    <CAlert id="qrOrderQRTypeDangerAlert" v-else color="danger"> <span
                            v-html="$t('warningStaticQR')"></span>
                    </CAlert>
                    <div>
                        <span id="qrOrderSecurityLevel">{{ $t('securityLevel') }} : {{ progressText }} </span>
                        <CProgress id="qrOrderSecurityProgress" :color="progressColor" :value="progressValue" />
                    </div>
                    <div class="mt-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="staticQRCondition.pinRequired"
                                id="static1">
                            <label class="form-check-label" for="static1">
                                {{ $t('enterPinDespt') }}<br>
                                <span class="text-muted">• {{ $t('suggest') }}</span>
                            </label>
                        </div>
                        <div class="form-check mt-2">
                            <input class="form-check-input" type="checkbox"
                                v-model="staticQRCondition.tableStatusRequired" id="static2">
                            <label class="form-check-label" for="static2">
                                {{ $t('staffOpenTableDespt') }}<br>
                                <span class="text-muted">• {{ $t('suggest') }}</span>
                            </label>
                        </div>
                        <div class="form-check mt-2">
                            <input :disabled="!datashop.location" class="form-check-input" type="checkbox"
                                v-model="staticQRCondition.locationRequired" id="static3">
                            <label class="form-check-label" for="static3">
                                {{ $t('customerRadiusDespt') }} <br>
                                <span class="text-muted">• {{ $t('customerLocationAccessDespt') }}</span><br>
                                <template v-if="!datashop.location"><span class="text-muted">• {{
                                    $t('storeLocationDespt') }}</span> &nbsp;
                                    <span @click="$router.push('/setting/shop')" style="text-decoration: underline;"
                                        class="text-dark">{{ $t('goToShopSetting')
                                        }}</span>
                                </template>
                            </label>
                        </div>
                    </div>
                </div>
                <template #footer-wrapper>
                    <CRow class="p-4">
                        <CCol>
                            <CButton @click="setQRType('STATIC')" block color="success" id="qrOrderSaveQRType">
                                {{ $t('confirm') }}
                            </CButton>
                        </CCol>
                        <CCol>
                            <CButton block color="light" @click="qrTypeModal = false" id="qrOrderCancelQRTypeModal">
                                {{ $t('cancel') }}
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CModal>

            <modal id="qrOrderConfirmSaveQRConfig" color="success" :show.sync="confirmModal"
                :title="$t('confirmSaveQRConfig')" v-on:isSave="savedata" centered :loading="loading">
                <div>
                    <br />
                    <h4 id="qrOrderConfirmSaveDialog" class="text-center text-dark">{{ $t('confirmSaveQRConfigDialog')
                        }}</h4>
                    <br />
                </div>
            </modal>
            <Logout id="qrOrderLogoutModal" :isVisible="loginAgainModal" @update:isVisible="loginAgainModal = $event" />
        </div>
        <div v-else>
            <div id="qrOrderSelfOrder" class="text-center mt-2" style="font-weight: bold; font-size:xx-large">
                {{ $t('selfOrder') }} <span class="badge badge-success ml-2 ">{{ $t('freeTrial') }}</span>
            </div>
            <br />
            <div id="qrOrderFreeTrialImage" style="display: flex; justify-content: center; align-items: center;">
                <img class="img-fluid mb-3" src="\img\qrAds.png" style="width: 55%; " />
            </div>
            <div class="m-5 text-center"
                style="display: flex; flex-direction: column; align-items: center; font-weight: bolder;">
                <span>
                    {{ $t("descriptionFreeTrialLine1") }}
                </span>
                <span>
                    {{ $t("descriptionFreeTrialLine2") }}
                </span>
                <span style="color: #3abc98;">
                    {{ $t("descriptionFreeTrialLine3") }}
                </span>
                <span>
                    {{ $t("descriptionFreeTrialLine4") }}
                </span>
                <br />

                <CButton id="qrOrderContactUsButton" class="line-btn"
                    ref="https://liff.line.me/1645278921-kWRPP32q/?accountId=silompos">
                    <img width="20%" src="\img\line-icon.png" />
                    <span class="h5 m-0 pl-2">{{
                        $t('contactus')
                        }}</span>
                </CButton>
            </div>
        </div>
    </div>
</template>

<script>
import Logout from '../../containers/Logout.vue';
import { mapGetters } from 'vuex'
import util from '@/util/util'
import permis from '@/util/permission'
import { Carousel, Slide } from 'vue-carousel';
import pos from '@/services/local';
import VueCropper from 'vue-cropperjs'
import mime from '@/util/mime'
import uploadImage from '@/util/uploadImage'
import StaticQRSticker from '../template/StaticQRSticker.vue'
import firebaseInit from '@/firebaseInit'
import HelpButton from '../../containers/HelpButton.vue';
import firebase from 'firebase';

export default {
    components: {
        Logout,
        Carousel,
        Slide,
        VueCropper,
        StaticQRSticker,
        HelpButton
    },
    data() {
        return {
            loginAgainModal: false,
            data: [],
            loading: true,
            message: '',
            footer: false,
            isSelfOrderEnabled: '',
            greetingMessage1: '',
            greetingMessage2: '',
            messageData: null,
            products: [],
            additionImageAmount: 5,
            localBannerImage: new Array(5),
            currentButton: 0,
            currentImg: '',
            additionalModal: false,
            loadingSaveimg: true,
            resizedImage: null,
            items: [],
            action: '',
            bannerData: '',
            option: ['object', 'link'],
            selectItemCard: false,
            bannerDataInputBox: '',
            orderShopCollection: null,
            confirmModal: false,
            alertStatus: false,
            coverImageModal: false,
            localCoverImage: '',
            pathToDelete: [],
            themeColor: [
                "#29B46B", // SilomPOS Green
                "#558B2F", // Forest Green
                "#00897B", // Teal
                "#ADD02C", // Lime Green
                "#B71C1C", // Crimson Red
                "#EF6C00", // Burnt Orange
                "#FFB300", // Amber
                "#880E4F", // Maroon
                "#C2185B", // Rose
                "#A64594", // Orchid
                "#144265", // Navy Blue
                "#0277BD", // Medium Blue
                "#75B7ED", // Sky Blue
                "#78909C", // Slate Gray
                "#A1887F", // Taupe
                "#6D4C41", // Mocha
                "#999999", // Grey
                "#212121", // Charcoal
            ],
            selectedTheme: '',
            showPolicyBox: false,
            policy: '',
            display: 'list',
            category: [],
            componentIndex: 0,
            coverExampleModal: false,
            qrType: 'DYNAMIC',
            qrTypeModal: false,
            staticQRCondition: {
                pinRequired: true,
                tableStatusRequired: true,
                locationRequired: false,
            },
            staticStackerModal: false,
            tables: [],
            saveTimeout: null,
            isDirty: false,
        }

    },
    computed: {
        ...mapGetters(['shops', 'users', 'isOwner', 'shopsetting', 'permissions']),
        isPermission() {
            return permis.findPermissionRead('selfOrder', this.$route.path)
        },
        isEditData() {
            return permis.findPermissionEdit('selfOrder', this.$route.path)
        },
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        selfOrderEnabled: {
            get() {
                const shop = this.shop || {};
                const delivery = shop.delivery || {};
                const isSelfOrderEnabled = delivery.isSelfOrderEnabled || false;
                const qrOrderMenu = this.permissions.setting.menu.find(menu => menu.label === 'qrOrder');
                const qrOrderLock = qrOrderMenu.lock
                this.getProduct()
                this.getOrderShopSetting()
                this.getCategory()
                if (isSelfOrderEnabled === true && qrOrderLock === false) {
                    return true;
                } else {
                    return false;
                }
            },
            set(value) {
                this.isSelfOrderEnabled = value
            },
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
        },
        shop() {
            return this.shops.find((i) => i.objectId === this.shopObjectId)
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
        datashop: {
            get() {
                return this.$store.getters.shopsetting
            },
            set(newValue) {
                return this.$store.dispatch('setShopSetting', newValue)
            },
        },
        currentTheme() {
            return {
                'background-color': this.selectedTheme
            }
        },
        recommendedProducts() {
            // Filter products to get only the recommended ones
            return this.products.filter(product => product.ProductSKU.isRecommend === true);
        },
        formattedPolicy() {
            return this.policy.replace(/\n/g, '<br>');
        },
        progressValue() {
            const { pinRequired, tableStatusRequired, locationRequired } = this.staticQRCondition;
            // Define values for each condition
            let pinRequiredValue = 45;
            let tableStatusRequiredValue = 45;
            let locationRequiredValue = 20;
            // Initialize progress value
            let progress = 5;
            // Add values based on which conditions are true
            if (pinRequired) {
                progress += pinRequiredValue;
            }
            if (tableStatusRequired) {
                progress += tableStatusRequiredValue;
            }

            if (locationRequired) {
                progress += locationRequiredValue;
            }
            // Return the final computed progress value
            return progress;
        },
        progressColor() {
            const { pinRequired, tableStatusRequired, locationRequired } = this.staticQRCondition;
            // When pinRequired and tableStatusRequired are true
            if (pinRequired && tableStatusRequired) {
                return 'success';
            }
            if (pinRequired || tableStatusRequired) {
                return 'success';
            }
            // When only locationRequired is true
            if (locationRequired) {
                return 'warning';
            }
            // When all conditions are false
            return 'danger';
        },
        progressText() {
            const { pinRequired, tableStatusRequired, locationRequired } = this.staticQRCondition;
            // When pinRequired and tableStatusRequired are true
            if (pinRequired && tableStatusRequired) {
                return this.$t('bestSecure');
            }
            if (pinRequired || tableStatusRequired) {
                return this.$t('goodSecure');
            }
            // When only locationRequired is true
            if (locationRequired) {
                return this.$t('moderateSecure');
            }
            // When all conditions are false
            return this.$t('noSecure');
        },
    },
    created() {
        this.getProduct()
        this.getOrderShopSetting()
        this.getCategory()
    },
    watch: {
    },
    methods: {
        ...util,
        logout() {
            this.loadingButton = false
            firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                    localStorage.clear()
                    window.location.href = '/login'
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        showMessageStatus() {
            this.alertStatus = true;
            setTimeout(() => {
                this.alertStatus = false;
            }, 5000);

        },
        setQRType(type) {
            this.qrType = type
            this.qrTypeModal = false
        },
        updateBannerData(productSKU) {
            this.bannerDataInputBox = productSKU.SKUName
            this.bannerData = productSKU.objectId
            this.selectItemCard = false
        },
        updateAction(action) {
            this.bannerDataInputBox = ''
            this.action = action
            this.selectItemCard = false

        },
        addObject(imagePath, action, data) {
            this.items.push({ imagePath, action, data });
        },
        setItemAtIndex(index, imagePath, action, data) {
            this.$set(this.items, index, { imagePath, action, data });
        },
        setImagePathAtIndex(index, newPath) {
            this.$set(this.items[index], 'imagePath', newPath);
        },
        getCurrentImage() {
            return this.currentImg
        },
        toggleCoverModal() {

            if (this.localCoverImage == '') {
                this.currentImg = ''
            } else {
                this.currentImg = this.localCoverImage
            }
            this.coverImageModal = true
        },
        async saveCoverImageLocal() {
            this.loadingSaveimg = false
            let resizedImage = await this.resizeImage(this.currentImg)
            this.localCoverImage = resizedImage
            this.currentImg = ''
            this.coverImageModal = false
            this.loadingSaveimg = true
            // this.getImageURL2()
        },
        async saveBannerLocal() {
            this.loadingSaveimg = false

            let resizedImage

            if (this.items[this.currentButton] == undefined && this.items[this.currentButton] !== null) {
                resizedImage = await this.resizeImage(this.currentImg)
                if (this.action == 'Product') {
                    this.addObject(resizedImage, this.action, this.bannerData)
                } else {
                    this.addObject(resizedImage, this.action, this.bannerDataInputBox)
                }
                this.localBannerImage[this.currentButton] = resizedImage
            } else {
                resizedImage = this.currentImg
                let data
                if (this.action == 'Product') {
                    data = this.bannerData
                } else {
                    data = this.bannerDataInputBox
                }
                this.localBannerImage[this.currentButton] = resizedImage
                this.setItemAtIndex(this.currentButton, resizedImage, this.action, data);
            }

            this.action = ''
            this.bannerData = ''
            this.currentImg = ''
            this.additionalModal = false
            this.loadingSaveimg = true
            // this.getImageURL2()
        },
        async getImageURL() {
            if (!this.localCoverImage.startsWith("https://firebasestorage")) {
                let ext = mime.getExtension(this.localCoverImage);
                if (ext == null) {
                    this.isload = false;
                } else {
                    const ownerUID = this.uid
                    const directoryPath = 'resources/' + ownerUID + '/resources/coverImage'
                    let imagePath = await uploadImage.onUpload2(directoryPath, this.localCoverImage)
                    if (!imagePath.startsWith("https://firebasestorage")) {
                        this.loginAgainModal = true
                    } else {
                        this.loginAgainModal = false
                        this.localCoverImage = imagePath
                    }
                }
            }
            for (let i = 0; i < this.items.length; i++) {
                if (this.localBannerImage[i] !== null && !this.localBannerImage[i].startsWith("https://firebasestorage")) {
                    let ext = mime.getExtension(this.localBannerImage[i]);
                    if (ext == null) {
                        this.isload = false;
                    } else {
                        const imageName = util.generateFilename('.' + ext)
                        const ownerUID = this.uid
                        const directoryPath = 'resources/' + ownerUID + '/resources/banner/' + imageName
                        let imagePath = await uploadImage.onUpload2(directoryPath, this.localBannerImage[i])
                        if (!imagePath.startsWith("https://firebasestorage")) {
                            this.loginAgainModal = true
                        } else {
                            this.loginAgainModal = false
                            this.setImagePathAtIndex(i, imagePath);
                        }
                    }
                }
            }
        },


        handleFileChange: function (event) {
            //this.isUpload = true
            this.currentImg = '';
            let file = event.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                this.currentImg = e.target.result;
                // this.isUpload = false
            };
        },
        async deleteImageFromFirebase() {
            for (const element of this.pathToDelete) {
                try {
                    await uploadImage.deleteImage(this.uid, this.shopObjectId, element)
                } catch (error) {
                    console.error('Error deleting image:', error)
                }
            }
            this.pathToDelete = []
        },
        async deleteLocalCoverImg() {
            this.localCoverImage = ''
            this.currentImg = ''
            this.coverImageModal = false
        },
        async deleteLocalImg() {
            if (this.localBannerImage[this.currentButton] !== undefined && this.localBannerImage[this.currentButton] !== null) {
                if (this.localBannerImage[this.currentButton].startsWith("https://firebasestorage")) {
                    this.pathToDelete.push(this.localBannerImage[this.currentButton])
                }

            }
            this.action = ''
            this.bannerDataInputBox = ''
            this.localBannerImage[this.currentButton] = null
            this.items[this.currentButton] = null
            this.currentImg = ''
            this.localBannerImage = this.localBannerImage.filter(localBannerImage => localBannerImage !== null);
            this.items = this.items.filter(item => item !== null);
            this.additionalModal = false
        },
        selectBannerImage(index) {
            this.currentButton = index
            if (this.localBannerImage[index] !== undefined && this.localBannerImage[index] !== null) {
                this.currentImg = this.localBannerImage[index] || ''

                if (this.items[index].action == 'Product') {
                    this.bannerDataInputBox = this.getSkuName(this.items[index].data)
                } else {
                    this.bannerDataInputBox = this.items[index].data || ''
                }

                this.bannerData = this.items[index].data || ''
                this.action = this.items[index].action || ''
            } else {
                this.currentImg = ''
                this.bannerDataInputBox = ''
                this.bannerData = ''
                this.action = ''
            }
            this.additionalModal = true
        },

        getSkuName(itemObjectId) {
            const product = this.products.find(product => product.productSKU.objectId === itemObjectId);
            if (product) {
                return product.ProductSKU.SKUName
            }
        },

        async resizeImage(base64Str, maxWidth = 1280, maxHeight = 640) {
            return new Promise((resolve) => {
                let img = new Image()
                img.src = base64Str
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = maxWidth
                    const MAX_HEIGHT = maxHeight
                    let width = img.width
                    let height = img.height

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width
                            width = MAX_WIDTH
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height
                            height = MAX_HEIGHT
                        }
                    }
                    canvas.width = width
                    canvas.height = height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    resolve(canvas.toDataURL())

                }
            })
        },

        getProduct(page = 1) {
            const uid = this.uid
            const categoryObjectId = ''
            let params = {
                shopObjectId: this.shopObjectId,
                page: page,
                limit: 50,
                searchVal: '',
            }
            if (categoryObjectId !== '') {
                params['categoryObjectId'] = categoryObjectId
            }

            pos({
                method: 'get',
                url: '/api/v1.0/' + uid + '/Shop/getproduct/view',
                params: params,
            }).then((res) => {
                this.products = res.data.data


            })
        },


        getOrderShopSetting() {
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                this.orderShopCollection = res?.data?.data[0] || null
                this.localCoverImage = this.orderShopCollection?.remoteCoverImagePath || ''
                this.greetingMessage1 = this.orderShopCollection?.greetingMessage?.[0] || ''
                this.greetingMessage2 = this.orderShopCollection?.greetingMessage?.[1] || ''
                this.display = this.orderShopCollection?.layout || 'list'
                this.policy = this.orderShopCollection?.policyDescription || ''
                this.selectedTheme = this.orderShopCollection?.themeColor || this.themeColor[0]
                this.qrType = this.orderShopCollection?.qrType || 'DYNAMIC'
                this.staticQRCondition = this.orderShopCollection?.staticQRCondition || {
                    pinRequired: true,
                    tableStatusRequired: true,
                    locationRequired: false,
                }

                if (this.orderShopCollection?.banner !== null) {
                    this.items = this.orderShopCollection?.banner || []
                    this.items.forEach((data, index) => {
                        this.localBannerImage[index] = data.imagePath;
                    });
                }
            })
            this.isDirty = false
        },
        getTable() {
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v1.0/' + this.uid + '/Shop/gettablefb/data',
                params: params,
            }).then((response) => {
                this.tables = response?.data?.data || []
                this.setTableLock()
            })
        },
        async setTableLock() {
            try {
                const uid = this.uid;
                // Initialize Firestore batch
                const batch = firebaseInit.db.batch();

                // Loop through each table to create batch operations
                this.tables.forEach((table) => {
                    // Skip the table if it already has the 'lockedBy' field
                    if (table.lockedBy) {
                        return; // Skip this table if it already has lockedBy
                    }
                    // Reference to each table document in Firestore
                    const tableRef = firebaseInit.db.doc(`/shop-database/${uid}/Table/${table.tableObjectId}`);

                    // Create the updated table object with lockedBy field
                    const updatedTable = {
                        ...table,
                        lockedBy: {
                            cashier: false,
                            pin: ""
                        }
                    };
                    // Add each set operation to the batch
                    batch.set(tableRef, updatedTable, { merge: true }); // Merge ensures that only the lockedBy field is added
                });

                // Commit the batch operation
                await batch.commit();
            } catch (error) {
                console.error('Error saving tables:', error);
            }
        },
        async savedata(alert = true) {
            this.loading = false
            this.items = this.items.filter(item => item !== null);
            await this.getImageURL()
            await this.deleteImageFromFirebase()

            const shopObjectId = this.shopObjectId
            const uid = `${localStorage.getItem('uid')}`

            if (this.qrType == "STATIC") {
                this.getTable()
            }
            let data = {
                "created_at": new Date(),
                "created_by_id": uid,
                "enabled": true,

                "shop": {
                    "objectId": shopObjectId,
                    "id": this.datashop.id,
                    "branchName": this.datashop.branchName
                },

                "shopId": this.datashop.id,
                "uid": uid,
                "banner": this.items,
                "remoteCoverImagePath": this.localCoverImage,
                "greetingMessage": [this.greetingMessage1, this.greetingMessage2],
                "policyDescription": this.policy,
                "layout": this.display,
                "themeColor": this.selectedTheme,
                "staticQRCondition": this.staticQRCondition,
                "qrType": this.qrType,

            }
            if (this.orderShopCollection == null) {
                pos({
                    method: 'post',
                    url: '/api/v2.0/' + this.uid + '/OrderShopSetting',
                    data: data,
                })
                    .then((res) => {
                        this.loading = true
                        this.confirmModal = false
                        if (alert == true) {
                            this.showMessageStatus()
                            window.scrollTo(0, 0)
                        }
                        this.getProduct()
                        this.getOrderShopSetting()
                    })
            } else {
                pos({
                    method: 'patch',
                    url: '/api/v2.0/' + this.uid + '/OrderShopSetting/' + this.orderShopCollection.objectId,
                    data: data,
                })
                    .then((res) => {
                        this.loading = true
                        this.confirmModal = false
                        if (alert == true) {
                            this.showMessageStatus()
                            window.scrollTo(0, 0)
                        }
                        this.getProduct()
                        this.getOrderShopSetting()
                    })
            }
            this.isDirty = false
        },
        getThemeColor(i) {
            return {
                'background-color': i
            }
        },
        setThemeColor(i) {
            this.selectedTheme = i
        },
        updateTheme(event) {
            this.selectedTheme = event.target.value;
        },
        getColorName(colorCode) {
            const colorMapping = {
                "#558B2F": "Forest Green",
                "#00897B": "Teal",
                "#29B46B": "SilomPOS Green",
                "#ADD02C": "Lime Green",
                "#B71C1C": "Crimson Red",
                "#EF6C00": "Burnt Orange",
                "#FFB300": "Amber",
                "#880E4F": "Maroon",
                "#C2185B": "Rose",
                "#A64594": "Orchid",
                "#144265": "Navy Blue",
                "#0277BD": "Medium Blue",
                "#75B7ED": "Sky Blue",
                "#78909C": "Slate Gray",
                "#A1887F": "Taupe",
                "#6D4C41": "Mocha",
                "#212121": "Charcoal",
                "#999999": "Neutral Grey"
            }
            return colorMapping[colorCode] || 'Unknown Color';
        },
        setDisplay(option) {
            this.display = option;
        },
        getCategory() {
            const uid = this.uid
            const shopObjectId = this.shopObjectId
            pos.get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId)
                .then((res) => {
                    this.category = res.data.data
                })
        },
        getToolTips(text) {
            return this.$t(text)
        },
        handleSticker(status) {
            this.loading = status
        },
        exportSticker() {
            this.loading = false
            this.$refs.staticSticker.exportToPDF()
        },
        setDirty() {
            this.isDirty = true
        },
        saveButtonClass(isDirty) {
            if (isDirty == false) {
                return 'floating-save-btn btn btn-success'
            } else {
                return 'floating-save-btn-dirty btn btn-success'
            }
        },
        autoSave() {
            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout); // Prevent multiple API calls
            }
            this.saveTimeout = setTimeout(() => {
                this.savedata(false); // Call the save function after 1 second delay
            }, 1500);
        }
    },
    watch: {
        '$i18n.locale'(newVal, oldVal) {
            this.componentIndex += 1
        },
        qrType(newVal, oldVal) {
            this.autoSave();
        },
        display(newVal, oldVal) {
            this.autoSave();
        },
        selectedTheme(newVal, oldVal) {
            this.autoSave();
        },
        greetingMessage1(newVal, oldVal) {
            this.setDirty();
        },
        greetingMessage2(newVal, oldVal) {
            this.setDirty();
        },
        policy(newVal, oldVal) {
            this.setDirty();
        },
        localCoverImage(newVal, oldVal) {
            this.setDirty();
        },
        localBannerImage: {
            handler: 'setDirty',
            deep: true
        },
    },
}
</script>

<style src="../../assets/styles/inventory.css" scoped></style>

<style src="../../assets/styles/multiselect.css" scoped></style>

<style>
.square-box {
    position: relative;
    width: 20%;
    overflow: hidden;
    border-radius: 3px;
}

/* .VueCarousel-wrapper {
    border-radius: 10px;
} */

.square-box:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.phone-mocking {
    padding: 10px;
    border-radius: 10px;
    width: 250px;
    height: 500px;
    background-color: none;
    margin-top: 20px;
    overflow-y: scroll;
}

.phone-mocking::-webkit-scrollbar {
    display: none;
}

.container-phone {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/img/iphone.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 300px;
    height: 600px;
    overflow-y: scroll;
}

.container-phone::-webkit-scrollbar {
    display: none;
}



.toggles {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    border-radius: 5px;
    width: 50px;
    height: 30px;
    color: #ffffff;
    font-size: small;
    opacity: 0.8;
}

.callStaff {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    border-radius: 5px;
    width: 50px;
    height: 30px;
    background-color: #29B46B;
    color: #ffffff;
    font-size: small;
}

.banner {
    border-radius: 10px;
    height: 100px;

}

.recommend-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: inline-flex;

}

.recommend-container::-webkit-scrollbar {
    display: none;
}

.image-input {
    background-color: rgb(230, 230, 230);
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.select-item-card {
    height: 300px;
    overflow-x: scroll;
}

.category-btn-active {
    font-size: x-small;
    background-color: #29B46B;
    width: fit-content;
    padding: 1cap;
    color: white;
    border-radius: 5px;
    padding-left: 2cap;
    padding-right: 2cap;
    margin: 1cap;
}

.category-btn {
    font-size: x-small;
    background-color: #eeeeee;
    white-space: nowrap;
    padding: 1cap;
    color: rgb(109, 109, 109);
    border-radius: 5px;
    padding-left: 2cap;
    padding-right: 2cap;
    margin: 1cap;
}

.theme-color {
    width: 40px;
    aspect-ratio: 1/1;
    border: none;
    border-radius: 7px;
    margin-right: 1cap;
    margin-bottom: 1cap;

}


.footer-home {
    position: absolute;
    top: 520px;
    left: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 240px;
    height: 40px;
    color: white;
    padding: 2cap;
    border-radius: 50px;

}

.footer-modal {
    text-align: center;
    width: 220px;
    height: 40px;
    color: white;
    padding: 2cap;
    border-radius: 50px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.policy-box {
    position: absolute;
    top: 26px;
    left: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 253px;
    height: 548px;
    border-radius: 36px;

}

.policy-modal {
    width: 95%;
    height: 250px;
    background-color: #ffffff;
    border-radius: 35px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;

}

.backdrop-pilocy {
    width: 100%;
    height: 100%;
    background-color: #191919;
    border-radius: 35px;
    opacity: 0.7;
}

.display-option {
    width: 100px;
    aspect-ratio: 1/1;
    background-color: #f9f9f9;
    border: 2px solid #eeeeee;
    margin-right: 1cap;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 8px;
}

.display-option-selected {
    width: 100px;
    aspect-ratio: 1/1;
    background-color: #3abc98;
    border: 2px solid #eeeeee;
    margin-right: 1cap;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 8px;
    color: white;
}

.display-option-selected:hover {
    color: white;
}

.qr-type-box {
    width: 100%;
    border-radius: 8px;
    border: 2px solid #eeeeee;
    background-color: #f9f9f9;
    display: flex;
    padding: 2cap
}

.badge-new {
    position: absolute;
    top: -10px;
    /* Adjust positioning as needed */
    right: -10px;
    /* Adjust positioning as needed */
    background-color: red;
    color: white;
    font-size: 14px;
    padding: 1px 6px;
    border-radius: 10px;
    font-weight: bold;
}

.static-sticker-btn {
    background-color: #ffffff;
    border: 2px solid #eeeeee;
    margin-right: 1cap;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 8px;
}

.static-qr-contact-line {
    background-color: #00b900;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    width: fit-content;
    justify-self: left;
}

.line-btn-q:hover {
    color: #fff;
}

.floating-save-btn {
    position: fixed;
    bottom: 25px;
    right: 100px;
    z-index: 1999;
    padding: 12px 54px;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background: #3abc98;
    color: white;
    border: none;
    cursor: pointer;
}

.floating-save-btn-dirty {
    position: fixed;
    bottom: 25px;
    right: 100px;
    z-index: 1999;
    padding: 12px 54px;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    background: #3abc98;
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    animation: pulseEffect 1.5s infinite;
}

/* Center the button on smaller screens (mobile) */
@media screen and (max-width: 768px) {
    .floating-save-btn {
        right: 50%;
        transform: translateX(50%);
    }

    .floating-save-btn-dirty {
        right: 50%;
        transform: translateX(50%);
    }
}


/* Pulsating animation */
@keyframes pulseEffect {
    0% {
        box-shadow: 0 0 0 0 rgba(58, 188, 152, 0.5);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(58, 188, 152, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(58, 188, 152, 0);
    }
}
</style>
